<template>
  <div class="" style="width: 100%;height: 100%;">
    <el-drawer size="335px" :modal="false" :append-to-body="false" :modal-append-to-body="false" :visible.sync="drawer"
      :with-header="false">
      <div class="drawer_box">
        <el-row style="margin-bottom: 10px;" class="flexEnd flex_1 ">
          <el-button size="small" type="primary" icon="el-icon-search" @click="onSearch">查询
          </el-button>
          <el-button size="small" type="primary" plain @click="onReset">重置
          </el-button>
        </el-row>
        <el-divider style="width: 100%"></el-divider>

        <el-form :model="searchForm" ref="queryForm" label-width="100px" inline>
          <el-form-item label="实验室管理员">
            <el-input v-model="searchForm.mohuTitle1" size="mini" placeholder="实验室管理员姓名"></el-input>
          </el-form-item>
          <el-form-item label="普通老师">
            <el-input v-model="searchForm.mohuTitle2" size="mini" placeholder="普通老师姓名"></el-input>
          </el-form-item>
          
        </el-form>
      </div>
    </el-drawer>


    <el-row ref="SXListTable" style="margin-bottom: 10px;" class="flexBetween flex_1 ">
      <div class="flex_1">
        <!--        <el-button type="primary" size="small" plain icon="el-icon-plus" @click="add">新增</el-button>
        <el-button style="margin-left: 10px;" type="danger" size="small" plain icon="el-icon-delete"
                   @click="delSlct">删除
        </el-button>-->

        <el-form :model="searchForm" ref="queryForm" label-width="10px" inline>

          <el-form-item label="">
            <el-input v-model="searchForm.title" size="mini" clearable placeholder="耗材名称"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="searchForm.zc_gztype" size="mini" clearable  placeholder="购置方式">
              <el-option v-for="(item, index) in purchaseType" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>

<!--          <el-form-item label="价值区间:" label-width="80px">
            <div class="flexStart">
              <el-input v-model="searchForm.jine1" size="mini" clearable  placeholder="最小值"></el-input>
              <span style="margin: 0 10px;">-</span>
              <el-input v-model="searchForm.jine2" size="mini" clearable  placeholder="最大值"></el-input>
            </div>
          </el-form-item>-->

<!--          <el-form-item label="">
            <el-date-picker v-model="searchForm.zc_date" clearable  type="date" size="mini" value-format="yyyy-MM-dd"
              placeholder="购置日期">
            </el-date-picker>
          </el-form-item>-->
<!--  -->
          <!-- <el-form-item label=""> -->
            <!-- <el-cascader ref="myCascader1" v-model="searchForm.zc_typeid" :options="typeList" size="mini" -->
              <!-- :props="optionProps1" :show-all-levels="true" filterable placeholder="耗材分类" -->
              <!-- @change="handleCascaderChange1"></el-cascader> -->
          <!-- </el-form-item> -->

          <el-form-item label="">
            <el-select v-model="searchForm.pinpai" clearable  size="mini" filterable placeholder="品牌">
              <el-option v-for="(item, index) in pinpaiList" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="">
            <el-select v-model="searchForm.xinghao" clearable  size="mini" filterable placeholder="型号">
              <el-option v-for="(item, index) in xinghaoList" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="">
            <el-cascader ref="myCascader" clearable  v-model="searchForm.zc_storeid" :options="areaList" size="mini"
              :props="optionProps" :show-all-levels="true" filterable placeholder="所在位置"
              @change="handleCascaderChange"></el-cascader>
          </el-form-item>

          <el-form-item label="">
            <el-select v-model="searchForm.store_gly_id"  clearable size="mini" :disabled="disabled" placeholder="仓管">
              <el-option label="请选择" value=""></el-option>
              <el-option :label="item.name" :value="item.union_id" v-for="(item, idx) in storeAdmin"
                :key="idx"></el-option>
            </el-select>
          </el-form-item>

<!--          <el-form-item label="">
            <el-select v-model="searchForm.status" size="mini" clearable  placeholder="状态">
              <el-option v-for="(item, index) in statusList" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>-->

          <el-form-item label="">

            <el-button icon="el-icon-search" size="small"  clearable type="primary" @click="onSearch"
              style="margin-left: 10px;">查询</el-button>
            <el-button icon="el-icon-download" size="small" clearable  type="primary" @click="exportXLS" plain
              style="margin-left: 10px;">导出</el-button>

            <el-button type="primary" size="small" @click="showYujingList" plain>预警列表</el-button>
          </el-form-item>




        </el-form>

        <!--  -->
        <!-- <el-cascader ref="myCascader1" v-model="searchForm.zc_typeid" :options="typeList" size="small" -->
        <!-- :props="optionProps1" :show-all-levels="true" filterable clearable placeholder="耗材分类" -->
        <!-- @change="handleCascaderChange1" style="width: 200px!important;"></el-cascader> -->
        <!-- <el-input v-model="searchForm.mohuTitle" size="small" clearable style="width: 250px!important;margin-left: 10px;" -->
        <!-- placeholder="模糊搜索"> -->
        <!-- </el-input> -->

        <!--        <el-button icon="el-icon-download" type="text" size="small" style="margin-left: 10px;">
          <a href="./downloadFiles/实训耗材导入模板1211.xls" target="_blank"
             style="text-decoration: none; color: inherit;font-size: 14px;">下载模板</a>
        </el-button>
        <el-link type="primary" @click="uploadXls" style="margin-left: 10px;" target="_blank"><i class="el-icon-plus"></i>导入耗材模板</el-link>-->
        <div style="display: none;">
          <el-upload action="" :multiple="true" :http-request="uploadOss" :on-success="uploadAttch"
            :show-file-list="false" name="image">

            <el-button size="mini" id="addXlsbtn">+</el-button>
          </el-upload>
        </div>
      </div>
      <div>


        <el-button type="text" @click="drawer = true" style="color: #999999;margin-left: 20px;">
          <i class="el-icon-s-operation"></i>&nbsp;高级搜索
        </el-button>
      </div>
    </el-row>

    <el-table :data="DataList" height="calc( 100% - 170px)" @selection-change="handleSelectionChange" style="width: 100%;"
      border :row-class-name="tableRowClassName">
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column type="index" label="序号" width="80px" align="center"></el-table-column>
      <el-table-column prop="title" label="名称(点击查看详情)" min-width="160px" align="center">
        <template slot-scope="scope">
          <div @click="showDetails(scope.row)" style="color: #409EFF;cursor: pointer;">{{ scope.row.title }}</div>
        </template>
      </el-table-column>
      <!-- <el-table-column v-if="false" prop="typename" label="分类" width="120px" align="center">
        <template slot-scope="scope">
          {{ scope.row.typename2 }} {{ scope.row.typename1 }}
        </template>
      </el-table-column> -->
      <el-table-column prop="storename" label="所在仓库" min-width="150px" align="center" show-overflow-tooltip />
      <el-table-column prop="pinpai" label="品牌" min-width="80px" align="center" />
      <el-table-column prop="xinghao" label="型号" :show-overflow-tooltip="true" min-width="80px" align="center" />
      <el-table-column prop="guige" label="规格" :show-overflow-tooltip="true" min-width="120px" align="center" />
      <!-- <el-table-column prop="jldw" label="计量单位"  min-width="85px" align="center"/> -->
      <!--      <el-table-column prop="jine" label="单价（加权平均值）" width="130" :show-overflow-tooltip="true">
        <template slot="header">
          <div style="text-align: center;">单价</div>
          <div style="text-align: center;">（加权平均值）</div>
        </template>
        <template slot-scope="scope">
          <div class="flexCenter">￥{{scope.row.jine}}</div>
        </template>
      </el-table-column>-->
      <el-table-column prop="store_num" label="剩余库存" :show-overflow-tooltip="true" width="85px" align="center" />
      <!--      <el-table-column prop="totalwan" label="总价(万元)" :show-overflow-tooltip="true" width="80">-->
      <!--        <template slot-scope="scope">-->
      <!--          <span>{{ scope.row.totalwan }}(W)</span>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column prop="total" label="总价(元)" :show-overflow-tooltip="true" width="80px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.total }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="yujing_num" label="预警数量" :show-overflow-tooltip="true" width="85px" align="center">
        <template slot-scope="scope">
          <span style="color: #f00">{{ scope.row.yujing_num }}</span>
        </template>
      </el-table-column>
      <el-table-column :render-header="renderHeader" prop="bgr" label="使用人" width="85px" align="center" />
      <el-table-column prop="delivery_date" label="验收日期" width="120px" align="center" />
      <el-table-column prop="checker" label="验收人" width="85px" align="center" />
      <el-table-column :render-header="renderHeader" prop="is_over_year" label="使用超一年" align="center" width="110px">
        <template slot-scope="scope">
          <span style="color: #f00">{{ scope.row.is_over_year ? '是' : scope.row.is_over_year === 0 ? "否" : '' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="160px" fixed="right" align="center">
        <template slot-scope="scope">
          <div class="flexStart">
            <template v-if="isAdmin">
              <el-tag size="mini" style="margin-left: 10px;cursor: pointer" @click="editItem(scope.row)"><i
                  class="el-icon-edit"></i></el-tag>
              <el-tag type="danger" size="mini" style="margin-left: 10px;cursor: pointer;"
                @click="removeItem(scope.row)"><i class="el-icon-delete"></i></el-tag>
            </template>

            <el-tag type="info" size="mini" style="margin-left: 10px;cursor: pointer;"
              @click="showLog(scope.row)">变更记录</el-tag>
            <!--          <el-dropdown style="margin-left: 10px;" trigger="click" size="small">-->
            <!--            <div class="el-dropdown-link">-->
            <!--              更多操作<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
            <!--            </div>-->
            <!--            <el-dropdown-menu slot="dropdown">-->
            <!--              <el-dropdown-item>-->
            <!--                <span @click="showStore(scope.row)">修改库存</span>-->
            <!--              </el-dropdown-item>-->

            <!--            </el-dropdown-menu>-->
            <!--          </el-dropdown>-->
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="cus_page" @size-change="handleSizeChange" @current-change="paginate"
      :current-page.sync="page.current_page" :page-sizes="[10, 50, 100]" :page-size="page.per_page"
      layout="total,sizes, prev, pager, next" :total="page.count">
    </el-pagination>
  </div>
</template>

<script>
import { export_to_excel } from "@/utils/Export2Excel";

export default {
  components: {},
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value && value != 0) {
        return callback(new Error('请填写入库数量!'));
      }
      if (!Number.isInteger(value)) {
        callback(new Error('请输入数字值'));
      } else {
        if (value <= 0) {
          callback(new Error('不能小于0'));
        } else {
          callback();
        }
      }
    };

    return {
      storeAdmin: [],

      searchForm: {},
      DataList: [],
      multipleSelection: [],
      queryForm: {},
      page: {
        current_page: 1,
        per_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },
      dialogTitle: '新增耗材',
      dialogVisible: false,
      EditItem: {},
      disabled: false,
      typeList: [],
      areaList: [],
      optionProps: { value: 'id', label: 'storename', emitPath: false, checkStrictly: true },
      optionProps1: { value: 'id', label: 'cname', emitPath: false },
      files: [],
      logList: [],
      dialogVisible_borrow: false,
      userinfo: {},
      temp_list: [],//todo 未用备用
      showUserDialog: false,
      userDialogType: null,
      dialogVisible_return: false,
      dialogVisible_distribute: false,
      dialogVisible_shenling: false,
      dialogVisible_withdraw: false,
      drawer: false,//高级搜索面板
      FieldItem: {},
      showCusField: false,
      curDate: '',
      printObj: {
        id: "#printDiv",
        popTitle: "",
        extraCss: "",
        extraHead: '<meta http-equiv="Content-Language" content="zh-cn"/>'
      },
      showPrinter: false,
      showBiaoqian: false,
      bqZcList: [],
      dialogErrorVisible: false,
      ErrLog: [],
      page_hc: {
        current_page: 1,
        pre_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },
      storeNum: null,
      rules_store: {
        num: [
          { validator: checkNum, trigger: 'blur' }
        ],
      },
      purchaseType: [],
      pinpaiList: [],
      xinghaoList: [],
      statusList: [],
      isAdmin: false
    }
  },
  mounted() {
    this.getList();
    this.getType();
    this.getArea();
    this.getCurDate();
    this.getStoreAdmins()
    this.getPurchaseType()
    this.getPinPaiList()
    this.getXinghaoList()
    this.getStatusList()
    let _this = this
    if (localStorage.getItem("user")) {
      _this.userinfo = JSON.parse(localStorage.getItem("user"))
      // console.log(_this.userinfo)
    }
    setTimeout(() => {
      if (this.$store.state.teacher.sys_roles) {
        this.isAdmin = this.$store.state.teacher.sys_roles.filter(item => item.role_code.indexOf('admin') > -1).length > 0
      }
    }, 500)
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (row.yujing_num >= row.store_num) {
        return 'danger-row';
      }
      return '';
    },
    getList() {
      let _this = this
      _this.DataList = []
      let params = {}, arr = []
      if (this.drawer) {
        params = { ..._this.searchForm }
        params.mohuTitle = _this.searchForm.mohuTitle||_this.searchForm.mohuTitle1||_this.searchForm.mohuTitle2
      } else {
        params.mohuTitle = _this.searchForm.mohuTitle||_this.searchForm.mohuTitle1||_this.searchForm.mohuTitle2
        params.zc_typeid = _this.searchForm.zc_typeid
      }
      params.page = _this.page.current_page ? _this.page.current_page : 1
      params.pagesize = _this.page.per_page ? _this.page.per_page : 50
      for(let k in this.searchForm){
        if(this.searchForm[k]){
          params[k] = this.searchForm[k]
        }
      }




      this.$http.post("/api/sx_hc_list", params).then(res => {
        if (res.data.data) {
          res.data.data.map(a => {

            try {
              a.remark = JSON.parse(a.remark)
            } catch (error) {
              a.remark = []
            }
            if (a.jine) {
              a.jine = parseFloat(a.jine).toFixed(2)
            } else {
              a.jine = 0
            }
            if (a.jine && a.store_num) {
              a.total = this.$mul(a.jine, a.store_num)
              a.totalwan = this.$div(a.total, 10000)
            } else {
              a.total = 0
              a.totalwan = 0
            }
          })
          this.DataList = res.data.data;
        }
        if (res.data && res.data.page) {
          this.page = res.data.page
          this.page_hc = res.data.page
        }
      })
    },
    getPurchaseType() {
      this.$http.post("/api/sys_dictionary_list", { key_type: "purchaseType" }).then(res => {
        if (res.data && res.data.length > 0) {
          this.purchaseType = JSON.parse(res.data[0].key_values)
        }
      })
    },
    getPinPaiList() {
      this.$http.post("/api/sx_hc_pinpai", { zc_typeid: this.searchForm.zc_typeid }).then(res => {
        if (res.data && res.data.length > 0) {
          this.pinpaiList = res.data
        }
      })
    },
    getXinghaoList() {
      this.$http.post("/api/sx_hc_xinghao", { zc_typeid: this.EditItem.zc_typeid }).then(res => {
        if (res.data && res.data.length > 0) {
          this.xinghaoList = res.data
        }
      })
    },
    getStoreAdmins() {
      this.$http.post("/api/zc_cg_list").then(res => {
        this.storeAdmin = res.data
      })
    },
    getStatusList() {
      this.$http.post("/api/sys_dictionary_list", { key_type: "sxhcStatus" }).then(res => {
        if (res.data && res.data.length > 0) {
          this.statusList = JSON.parse(res.data[0].key_values)
        }
      })
    },
    // 新增
    add() {
      this.$$parent(this, 'add').then(r => {
      })
    },
    editItem(row, flag) {
      this.$$parent(this, 'editItem', row, flag).then(r => {
      })

    },
    removeItem(item) {
      this.$$parent(this, 'removeItem', item).then(r => {
      })
    },
    showLog(row) {
      this.$$parent(this, 'showLog', row).then(r => {
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(val)
    },
    delSlct() {
      this.$$parent(this, 'delSlct', this.multipleSelection).then(r => {
      })
    },


    //导入
    uploadXls() {
      document.getElementById('addXlsbtn').click()
    },
    uploadAttch(e) {
      this.$$parent(this, 'uploadAttch', e).then(r => {
      })
    },

    //增加库存
    showStore(row) {
      this.$$parent(this, 'showStore', row).then(r => {
      })
    },


    onSearch() {
      this.page.current_page = 1
      this.getList()
    },
    onReset() {
      this.searchForm = {}
      this.page.current_page = 1
      this.getList()
    },
    showYujingList() {
      this.$$parent(this, 'showYujingList')
    },
    dep_Arr(arr) {
      let a = true
      let fun = function (b) {
        b.map(s => {
          if (s.store_num <= 0) {
            a = false
            return;
          }
        })
      }
      fun(arr)
      return a
    },


    getType() {
      let _this = this
      this.$http.post("/api/zc_type", { type: 'sx' }).then(res => {
        _this.deleteEmptyGroup(res.data).then(result => {
          _this.typeList = result
        })
      })
    },
    getArea() {
      let _this = this
      this.$http.post("/api/zc_store").then(res => {
        _this.deleteEmptyGroup(res.data).then(result => {
          _this.areaList = result
        })
      })
    },
    // 处理没有children的分组
    deleteEmptyGroup(treeData) {
      return new Promise(resolve => {
        function traversal(data) {
          data.map(item => {
            for (let info in item) {
              if (item['children']) {
                if (item['children'].length > 0) {
                  traversal(item['children'])
                }
                if (item['children'].length == 0) {
                  delete item['children']
                }
              }
            }
          })
        }

        traversal(treeData)
        resolve(treeData)
      })
    },

    showDetails(row) {
      let _this = this
      this.editItem(row, true)
      this.dialogTitle = '耗材详情'
      this.dialogVisible = true
      this.disabled = true
    },

    handleSizeChange(val) {
      this.page.current_page = 1
      this.page.per_page = val
      this.getList();
    },
    paginate(val) {
      this.page.current_page = val
      this.getList();
    },

    handleCascaderChange(e) {
      console.log(e)
    },
    handleCascaderChange1(e) {
      if (e && this.searchForm.zc_typeid) {
        this.getPinPaiList()
        this.getXinghaoList()
      }
    },

    showUser(type) {
      if (type) {
        this.userDialogType = type
      }
      this.showUserDialog = true
    },

    getUser(e) {
      console.log(e)
      let _this = this
      let arr = []
      if (e && e.length > 0) {
        if (this.userDialogType == 1) {
          e.map((item, idx) => {
            arr.push({
              union_id: item.union_id,
              name: item.name,
            })
            if (_this.EditItem.target_users && _this.EditItem.target_users.length > 0) {
              _this.EditItem.target_users.map(orgnl => {
                if (orgnl.union_id == item.union_id) {
                  arr.splice(idx, 1)
                }
              })
            }
          })
          _this.EditItem.target_users = _this.EditItem.target_users.concat(arr)
          _this.EditItem = { ..._this.EditItem }
        } else if (this.userDialogType == 2) {
          _this.EditItem.target_uid = e[0].union_id
          _this.EditItem.name = e[0].name
          _this.EditItem = { ..._this.EditItem }
        }

      }
      _this.showUserDialog = false
    },

    getCurDate() {
      let d = new Date();
      let year = d.getFullYear(); //获取完整的年份(4位)
      let month = d.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      let date = d.getDate(); //获取当前日(1-31)
      d.getDay(); //获取当前星期X(0-6,0代表星期天)
      d.getTime(); //获取当前时间(从1970.1.1开始的毫秒数)
      d.getHours(); //获取当前小时数(0-23)
      d.getMinutes(); //获取当前分钟数(0-59)
      d.getSeconds(); //获取当前秒数(0-59)
      d.getMilliseconds(); //获取当前毫秒数(0-999)
      d.toLocaleDateString(); //获取当前日期
      let mytime = d.toLocaleTimeString(); //获取当前时间
      d.toLocaleString(); //获取日期与时间
      this.curDate = `${year}-${month}-${date}`
    },
    exportXLS() {
      if (this.multipleSelection.length > 0) {
        this.exportData(this.multipleSelection)
      } else {
        let params = {}
        if (this.drawer) {
          params = { ...this.searchForm }
        } else {
          params.mohuTitle = this.searchForm.mohuTitle
          params.zc_typeid = this.searchForm.zc_typeid
        }
        this.$http.post('/api/sx_hc_export', params).then(res => {
          if (res.data && res.data.length > 0) {
            this.exportData(res.data)
          }
        })
      }
    },
    // 在 methods 中添加 renderHeader 方法1
    renderHeader(h, { column }) {
      let header = column.label.split('/')
      return [h('span', [
        h('span', {}, header[0]),
        h('br'),
        h('span', {}, header[1])
      ])];
    },
    exportData(data) {
      let exportData = []
      data.forEach((item, index) => {
        exportData.push([
          ++index,
          item.title || "",
          item.xinghao || "",
          item.guige || "",
          item.store_num || "",
          item.jine || "",
          item.bgr || "",
          item.storename || "",
          item.delivery_date || "",
          item.checker || "",
          item.is_over_year == 1 ? '是' : item.is_over_year == 0 ? "否" : ""
        ])
      })
      exportData.unshift(['行次', '耗材名称', '型号', '规格', '数量', '单价', '使用人/保管人', '存放地', '验收日期', '验收人', '使用时长是否超过一年'])
      exportData.unshift(['实训基地耗材明细表'])
      let wsCols = [10, 20, 30, 40, 10, 10, 30, 20, 30, 20, 30]
      export_to_excel(exportData, '实训基地耗材明细表', wsCols)
    },
  }
}
</script>
<style>
.el-table .danger-row {
  background: #F6E0C0;
}
</style>

